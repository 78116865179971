<template>
	<div class="orderPharmacy">
		<el-card class="box-card father-card" shadow="never">
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<el-form-item label="订单编号">
						<el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item label="设备名称">
						<el-input v-model="form.medicalsetName" placeholder="请输入设备" clearable></el-input>
					</el-form-item>
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="success" icon="el-icon-search" @click="search()">搜索</el-button>
						<el-button type="primary" icon="el-icon-delete" @click="reset">重置</el-button>
						<el-button v-permission="['/medicalAdd']" v-if='isOrderCompany' type="primary" icon="el-icon-plus" @click="add()">添加</el-button>
					</el-form-item>
				</el-form>
			</el-row>
			<el-row class="tab">
				<!-- 表格区域 -->
				<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="medicalsetName" label="设备名称" align="center"></el-table-column>
					<el-table-column prop="medicalsetType" label="类型" align="center">
						<template slot-scope="scope">
							<span effect="dark" v-if="scope.row.medicalsetType==='0'">{{ '医疗设备' }}</span>

							<span type="success" effect="dark" v-else>{{ '设备' }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="medicalsetMoney" label="设备费用" align="center"></el-table-column>
					<el-table-column prop="medicalsetNumber"  label="使用数量" align="center"></el-table-column>
					<el-table-column prop="medicalsetMoneyTotal" label="设备单项总" align="center"></el-table-column>
					<el-table-column prop="remark" label="备注" align="center"></el-table-column>
					<el-table-column prop="createTime" label="订单时间" align="center"></el-table-column>

					<!-- <el-table-column label="详情" align="center">
						<template slot-scope="scope">
							<el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
						</template>
					</el-table-column> -->
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-popconfirm v-if='isOrderCompany' v-permission="['/medicalDel']" confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
								icon-color="red" title="确定要删除该订单费用吗？" @confirm="ahead(scope.row)">
								<el-button type="danger" slot="reference">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.pageNo" :page-sizes="[1, 10, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				<!-- 订单详情弹出框 -->
				<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
					<span slot="title" class="dialog-Title">订单设备详情</span>
					<el-form :model="orderForm" inline ref="orderForm">
						<div class="xbrogcorf">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									订单编号</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderNo}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									医用品</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.medicalsetName}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">类型
								</div>
								<div style="padding: 10px 20px;"> <span v-if="orderForm.medicalsetType == 0">医疗设备</span>
             <span v-if="orderForm.medicalsetType == 1">设备</span></div>
							</div>
						</div>
						<div class="xbrogcorf">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									设备费用</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.medicalsetMoney}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									使用数量</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.medicalsetNumber}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									医用品单项总</div>
								<div style="padding: 10px 20px;">{{orderForm.medicalsetMoneyTotal}}</div>
							</div>
						</div>
						<div class="xbrogcorf">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">备注
								</div>
								<div style="padding: 10px 20px;width: 550px;">{{orderForm.remark}}</div>
							</div>
						</div>

					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="orderDialogFormVisible=false">确 认</el-button>
					</div>
				</el-dialog>
				<!-- 添加(设备药品)弹出框 -->
				<el-dialog :visible.sync="orderDialogotherexpenses" class="order-dialog">
					<span slot="title" class="dialog-Title">添加订单设备费用</span>
					<el-form :model="addorderotter" inline ref="addorderotter">
						<el-popover placement="bottom-start" v-model="isVerification" trigger="click"
							class="dy-popover">
							<div class="dy-content">
								<div class="content-table">
									<el-table :data="shiorderForm" height="500" border style="width: 100%">
										<el-table-column prop="orderNo" label="订单编号" width="150"></el-table-column>
										<el-table-column prop="memberName" label="下单用户"></el-table-column>
										<el-table-column prop="memberMobile" label="手机号码"></el-table-column>
										<el-table-column label="订单状态" align="center">
											<template slot-scope="scope">
												<el-tag effect="dark" v-if="scope.row.status === '0'">{{ "新增" }}
												</el-tag>
												<el-tag effect="dark" type="success"
													v-else-if="scope.row.status === '1'">{{ "已派单" }}</el-tag>
												<el-tag effect="dark" type="success"
													v-else-if="scope.row.status === '2'">{{ "已接单" }}</el-tag>
												<el-tag effect="dark" type="success"
													v-else-if="scope.row.status === '3'">{{ "变更" }}</el-tag>
												<el-tag effect="dark" type="success"
													v-else-if="scope.row.status === '9'">{{ "到达出发地" }}</el-tag>
												<el-tag effect="dark" type="success"
													v-else-if="scope.row.status === '10'">{{ "到达目的地" }}</el-tag>
											</template>
										</el-table-column>
										<el-table-column label="操作" width="70">
											<template slot-scope="scope">
												<el-button type="primary" size="small" @click="selorderNo(scope.row)">确认
												</el-button>
											</template>
										</el-table-column>
									</el-table>
                                    <pagination v-show="shiorderPage.total>0" :total="shiorderPage.total" :page.sync="shiorderPage.PageNo" :limit.sync="shiorderPage.pageSize" @pagination="getshiOrderList" />

								</div>
							</div>
							<div class="xbrogcorf" slot="reference">
								<div style="background-color: #F3F6FA;padding: 10px;width: 110px;text-align: center;">
									订单编号</div>
								<input placeholder="请选择订单编号" v-model="addorderotter.orderNo" readonly
									style="border: 0px;padding-left: 15px;outline: none;width: 100%;" />
							</div>
						</el-popover>
						<el-popover placement="bottom-start" v-model="isVerificationyy" trigger="click"
							class="dy-popover">
							<div class="dy-content">
								<div class="content-table">
									<el-table :data="shiorderyy" height="500" border style="width: 100%">
										<el-table-column prop="medicalsetName" label="设备名称" width="150">
										</el-table-column>
										<el-table-column prop="medicalsetMoney" label="单价"></el-table-column>
										<el-table-column prop="remark" label="备注"></el-table-column>
										<el-table-column label="操作" width="70">
											<template slot-scope="scope">
												<el-button type="primary" size="small" @click="selyy(scope.row)">确认
												</el-button>
											</template>
										</el-table-column>
									</el-table>
                                    <pagination v-show="shiorderyyPage.total>0" :total="shiorderyyPage.total" :page.sync="shiorderyyPage.PageNo" :limit.sync="shiorderyyPage.pageSize" @pagination="getOrderyyList" />

								</div>
							</div>
							<div class="xbrogcorf" slot="reference">
								<div style="background-color: #F3F6FA;padding: 10px;width: 110px;text-align: center;">
									设备名称</div>
								<input placeholder="请选择设备" v-model="shiorderyyForm.medicalsetName" readonly
									style="border: 0px;padding-left: 15px;outline: none;width: 100%;" />
							</div>
						</el-popover>
						<div class="xbrogcorf" v-if="shiorderyyForm.medicalsetType">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">类型
								</div>
								<input placeholder="请输入项目费用" v-if="shiorderyyForm.medicalsetType == 0"
									style="width: 550px;" value="医疗设备" disabled />
								<input placeholder="请输入项目费用" v-if="shiorderyyForm.medicalsetType == 1"
									style="width: 550px;" value="设备" disabled />
							</div>
						</div>
						<div class="xbrogcorf" v-if="shiorderyyForm.medicalsetMoney">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									设备单价</div>
								<input placeholder="请选择设备" v-model="shiorderyyForm.medicalsetMoney"
									style="width: 550px;" disabled />
							</div>
						</div>
						<div class="xbrogcorf">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									使用数量</div>
								<!-- <input v-input-limit placeholder="请输入使用数量" v-model="shiorderyyForm.medicalsetNumber"
									style="width: 550px;" /> -->
                                    <!-- {{shiorderyyForm.medicalsetNumber}}9999 -->
                                <el-input-number  v-model="shiorderyyForm.medicalsetNumber"  :min="1" :max="100" label="请输入使用数量"></el-input-number>
							</div>
						</div>
						<div class="xbrogcorf">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">备注
								</div>
								<input placeholder="请输入备注" v-model="shiorderyyForm.remark" style="width: 550px;" />
							</div>
						</div>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="addotherexpenses()">确 认</el-button>
					</div>
				</el-dialog>
			</el-row>
		</el-card>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
// import { mapGetters } from 'vuex'
import { isOrderCompany } from '@/utils'

export default {
  name: 'orderPharmacy',
  components: {
    Pagination
  },
  computed: {

  },
  data () {
    return {
      shiorderyy: [],
      isOrderCompany: isOrderCompany(),
      // 药品分页
      shiorderyyPage: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1, // 当前页数
        total: 0
      },
      // 订单编号分页
      shiorderPage: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1, // 当前页数
        total: 0
      },
      List: [], // 表格数据源
      form: {
        orderNo: '',
        medicalsetName: ''
      }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderDialogotherexpenses: false,
      orderForm: {},
      addorderotter: {},
      isVerification: false,
      isVerificationyy: false,
      shiorderyyForm: {
        medicalsetNumber: 1
      },
      shiorderForm: {},
      orderFormRules: {
        dispatchItem: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'blur'
        }]
      }, // 编辑资料表单验证规则
      isdisable: true
    }
  },
  created () {
    this.getOrderList()
    this.getshiOrderList()
    // 获取医药列表
    this.getOrderyyList()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getOrderList()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getOrderList()
    },
    // 获取医药列表
    async getOrderyyList () {
      const {
        data: res
      } = await this.$http.post('pmService/tInfoMedicalset/getTInfoMedicalsetList', {
        pageSize: this.shiorderyyPage.pageSize,
        pageNo: this.shiorderyyPage.PageNo,
        status: 1
      })
      this.shiorderyy = res.data
      this.shiorderyyPage.total = Number(res.pageBean.pageDataCount)
      console.log(res)
    },
    // 获取订单设备记录
    async getOrderList () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDispatchMedicalset/getTInfoDispatchMedicalsetList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        headers: {
          AUTH_TYPE: 'orderMedicalset'
        }
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.List = res.data.records
      this.total = +res.pageBean.pageDataCount
    },
    // 点击搜索按钮触发
    async search () {
      // 调接口，进行查询
      this.paging.PageNo = 1
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDispatchMedicalset/getTInfoDispatchMedicalsetList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        medicalsetName: this.form.medicalsetName,
        headers: {
          AUTH_TYPE: 'orderMedicalset'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.List = res.data.records
      this.total = +res.data.total

      // 判断有输入账号
      // if (this.form.orderNo === '') {
      // 	this.getOrderList()

      // } else {

      // }
    },
    // 确定订单号
    selorderNo (data) {
      console.log(data)
      this.addorderotter.orderNo = data.orderNo
      this.isVerification = false
    },
    selyy (data) {
      this.shiorderyyForm.medicalsetName = data.medicalsetName
      this.shiorderyyForm.medicalsetId = data.uuid
      this.shiorderyyForm.medicalsetType = data.medicalsetType
      this.shiorderyyForm.medicalsetMoney = data.medicalsetMoney
      this.shiorderyyForm.remark = data.remark
      this.isVerificationyy = false
    },
    async addotherexpenses () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDispatchMedicalset/addTInfoDispatchMedicalset', {
        orderNo: this.addorderotter.orderNo,
        medicalsetName: this.shiorderyyForm.medicalsetName,
        medicalsetId: this.shiorderyyForm.medicalsetId,
        medicalsetMoney: this.shiorderyyForm.projectcost,
        medicalsetNumber: this.shiorderyyForm.medicalsetNumber,
        medicalsetType: this.shiorderyyForm.medicalsetType,
        remark: this.shiorderyyForm.remark,
        createBy: sessionStorage.getItem('userId')
      })
      if (res.statusCode !== '200') return this.$message.error('新增失败')
      this.addorderotter.orderNo = ''
      this.shiorderyyForm.medicalsetName = ''
      this.shiorderyyForm.medicalsetId = ''
      this.shiorderyyForm.medicalsetMoney = ''
      this.shiorderyyForm.medicalsetNumber = ''
      this.shiorderyyForm.medicalsetType = ''
      this.shiorderyyForm.remark = ''
      this.orderDialogotherexpenses = false
      this.getOrderList()
    },
    // 获取还未结束的订单
    async getshiOrderList () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/selectOrderHistoryDispatchList', {
        pageSize: this.shiorderPage.pageSize,
        pageNo: this.shiorderPage.PageNo
      })
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')

      this.shiorderForm = res.data
      this.shiorderPage.total = Number(res.pageBean.pageDataCount)
      console.log(this.shiorderForm)
    },
    // 点击添加按钮触发
    add () {
      this.orderDialogotherexpenses = true
    },
    // /pmService/tInfoDispatchMedicalset/addTInfoDispatchMedicalset
    // 点击删除按钮触发
    async ahead (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDispatchMedicalset/deleteTInfoDispatchMedicalset', {
        uuid: v.uuid
      })
      if (res.statusCode !== '200') return this.$message.error('删除失败')
      this.$message.success('删除成功')
      this.getOrderList()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.orderForm = v
      this.orderDialogFormVisible = true
    },
    reset () { // 重置
      this.form.orderNo = ''
      this.form.medicalsetName = ''
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search()
    }
  }

}
</script>
<style lang="less" scoped>
	.orderPharmacy {
		width: 100%;
		height: 100%;

		.father-card {
			.btns {
				margin-bottom: 10px;
			}

			.tab {
				.el-pagination {
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}

				.order-dialog {
					.cflex {
						display: flex;

						input {
							border: 0;
							outline: none;
							padding-left: 20px;
						}
					}

					.xbrogcorf {
						background-color: #fff;
						display: flex;
						border-bottom: #F3F6FA 2px solid;
					}

					.dialog-Title {
						color: #0097fe;
						font-size: 20px;
						font-weight: 700;
					}

					/deep/ .el-form {
						.subtitle {
							color: #0097fe;
							font-size: 14px;
							font-weight: 700;
							margin-bottom: 20px;
							padding-left: 6px;
							border-left: 5px solid #0097fe;
						}

						.el-row {
							display: flex;

							.el-form-item {
								flex: 1;
								display: flex;

								.el-form-item__content {
									flex: 1;

									.el-input {
										width: 100%;

										.el-input__inner {
											width: 100%;
										}
									}

									.el-select {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
